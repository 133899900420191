<template>
    <div>
        <label class="col-form-label">{{ labelValue }}</label>
        <v-select :close-on-select="true" :placeholder="placeholder" v-model="selectedOption" :options="options"
            label="name" input-id="add-text" @input="handleInput"></v-select>
    </div>
</template>

<script>
import vSelect from "vue-select";
export default {
    components: {
        vSelect
    },
    props: ['options', 'selectedOption', 'labelValue', 'placeholder'],
    methods: {
        handleInput() {
            this.$emit('getUserDropDown', this.selectedOption);
        },
    },
};
</script>
